$--color-primary: #5072ff !default;
$--color-success: #5072ff !default;
$--color-warning: #f4d578 !default;
$--color-danger: #f56c6c !default;
$--color-info: #909399 !default;
$--color-light: #f3f5fd !default;

/* ----------------------------
# Element
------------------------------ */
$--color-text-regular: #525662;
$--color-text-primary: $--color-primary;
$--disabled-color-base: #a1a1a1;

/* Border
-------------------------- */
$--border-color-base: #e6e6e6 !default;
$--border-color-hover: $--color-primary;
$--font-size-base: 16px !default;

/* Tooltip
-------------------------- */
$--tooltip-fill: $--color-light;
$--tooltip-color: #000 !default;
$--tooltip-font-size: 12px !default;

/* Select
-------------------------- */
$--select-dropdown-background: #fff !default;
$--select-dropdown-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04) !default;
$--select-dropdown-empty-color: #999 !default;
$--select-dropdown-max-height: 182px !default;
$--select-dropdown-padding: 6px 0 !default;
$--select-dropdown-empty-padding: 10px 0 !default;
$--select-dropdown-border: solid 1px #fff !default;

/* Dialog
-------------------------- */
$--dialog-padding-primary: 32px !default;

/* Popover
-------------------------- */
$--popover-font-size: $--font-size-base - 2px;

@import "../../theme-chalk/src/common/var";
@import "../../theme-chalk/src/mixins/mixins";

/* ----------------------------
# Jumbleberry
------------------------------ */

/* JB-Font
-------------------------- */
$--jb-font-path: '~@/theme/jumbleberry/fonts' !default;

/* ----------------------------
#JB-COLORS
------------------------------ */
$--jb-dark-primary-color: #5072ff !default;
$--jb-light-primary-color: #4561ff !default;
$--jb-black-primary-color: #162765 !default;
$--jb-bg-primary-color: #fff !default;
$--jb-bg-accent-color: #f3f5fd !default;

/*
#JB-PLACEHOLDER-COLOR
------------------------------ */
$--color-text-jb-placeholder: #c1c1c1 !default;

/* Font
-------------------------- */
$--font-size-medium: 16px !default;
$--jb-font-size-small: 14px !default;

/*
#JB-SPECIFIC-META-COPY
------------------------------ */
$--jb-meta-desc-copy: 13px !default;
$--jb-meta-text-align: left !default;
$--jb-meta-line-height: 20px !default;

/*
#JB-SPECIFIC-ICON
------------------------------ */
$--jb-icon-checkmark-size: 24px !default;

/*
#JB-SPECIFIC-MEDIA-ICON-OBJECT
------------------------------ */
$--jb-media-icon-flex: flex !default;
$--jb-media-icon-padding: 14px 0 !default;
$--jb-media-icon-align: flex-start !default;
$--jb-media-icon-margin: 0 !default;
$--jb-media-icon-right-text-align: left !default;
$--jb-media-icon-right-padding: 10px !default;
$--jb-media-icon-right-line-height: 16px !default;
$--jb-media-icon-description: 12px;

/*
#Form
-------------------------- */

/*
#Form-Item
-------------------------- */
$--form-item-margin-bottom: 10px;

/*
#JB-SPECIFIC-INPUT
------------------------------ */

/*
#INPUT
------------------------------ */
$--input-letter-spacing: 0.5px !default;
$--input-font-size: $--font-size-large !default;
$--input-icon-jb-font-size: 20px !default;
$--jb-input-icon-position: 15px !default;
$--input-inner-jb-padding: 27px 15px 15px !default;
$--input-jb-height: 60px !default;
$--input-jb-mobile-height: 47px !default;
$--input-placeholder-color: $--color-text-jb-placeholder !default;
$--input-focus-border: $--color-primary;
$--input-hover-border: $--color-primary;

/*
#JB-hover-autocomplete
-------------------------- */
$--jb-hover-autocomplete: $--jb-light-primary-color !default;

/*
#JB-SPECIFIC-BUTTON
------------------------------ */
$--button-jb-height: 60px !default;
$--jb-button-border-focus: 1px;
$--jb-button-border-active: 1px solid;

/*
#JB-NEXT-BUTTON
------------------------------ */
$--jb-next-box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.13);

/*
#JB-BOX-SHADOW
------------------------------ */
$--jb-box-shadow-base: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04) !default;

/*
#JB-POPOVER-BORDER-COLOR
------------------------------ */
$--jb-popover-border-color: $--color-light !default;

/*
#JB-SPECIFIC-COLOR
------------------------------ */
$--card-main-jb-padding: 34px 50px 39px !default;

/*
#JB-HEADER-h2
-------------------------- */
$--jb-header-h2: 24px !default;

/*
#JB-MESSAGE-PAGE
------------------------------ */
$--jb-message-content-margin-title: 0 !default;
$--jb-message-content-margin-bottom: 8px !default;
$--jb-message-img-margin: 30px 0 !default;
$--jb-message-img-width: 177px !default;
$--jb-message-img-height: 177px !default;

/* JB-MESSAGE
-------------------------- */
$--jb-message-min-width: 320px !default;

/* JB-CHECKBOX
-------------------------- */
$--jb-checkbox-padding: 20px 15px !default;
$--jb-checkbox-label-padding-left: 3px !default;
$--jb-checkbox-margin: 12px !default;

/* JB-DRAGGABLE
-------------------------- */
$--jb-draggable-margin: 16px 0 !default;

/* JB SURVEY
-------------------------- */
$--jb-survey-sub-header: #757575 !default;

/* JB BACKLINK
-------------------------- */
$--jb-backlink-padding: 12px 12px !default;

$--xs: 450px !default;
$--breakpoints-spec: (
  'xs-and-up' : (min-width: $--xs),
  'xs-only': "(min-width: #{$--xs}) and (max-width: #{$--sm - 1})",
  'xs-and-down': (max-width: $--sm - 1),
  'sm-and-up' : (min-width: $--sm),
  'sm-only': "(min-width: #{$--sm}) and (max-width: #{$--md - 1})",
  'sm-and-down': (max-width: $--md - 1),
  'md-and-up' : (min-width: $--md),
  'md-only': "(min-width: #{$--md}) and (max-width: #{$--lg - 1})",
  'md-and-down': (max-width: $--lg - 1),
  'lg-and-up' : (min-width: $--lg),
  'lg-only': "(min-width: #{$--lg}) and (max-width: #{$--xl - 1})",
  'lg-and-down': (max-width: $--xl - 1),
  'xl-only' : (min-width: $--xl),
);
