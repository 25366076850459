
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        








































































































.survey-summary-component {
  margin: 24px 0 0;

  .survey-summary-header {
    margin-bottom: 0;
    margin-top: 0;
  }

  .survey-summary-sub-header {
    font-size: $--jb-font-size-small;
    margin-bottom: 32px;
    margin-top: 8px;
  }

  .summary-card-cont {
    border-radius: 4px 4px 0 0;
    position: relative;
  }

  .summary-card {
    background-color: $--jb-bg-primary-color;
    border: 1px solid $--color-primary;
    border-bottom: none;
    border-radius: 4px 2px 0 0;
    max-height: 50vh;
    max-height: calc(50 * var(--vh, 1vh));
    overflow-y: scroll;
    padding: 5px 24px 24px;

    @media (max-width: $--sm) {
      max-height: 40vh;
      max-height: calc(40 * var(--vh, 1vh));
    }
  }

  .next-button {
    margin-top: 20px;
  }

  .terms-and-conds {
    background: linear-gradient(to top, #eff1fd, white);
    border: 1px solid $--color-primary;
    border-radius: 0 0 4px 4px;
    border-top: 1px #ccc solid;
    margin-bottom: 12px;
    padding: 15px 24px;

    .normal {
      color: $--color-text-regular !important;

      a {
        white-space: nowrap;
      }
    }

    &.no-height {
      padding: 0;
    }

    label {
      display: flex;
      white-space: normal;
    }
  }
}
