
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
























































































.survey-summary-splash-item {
  ul,
  ol {
    margin: 0 !important;
    padding-left: 16px !important;

    li {
      list-style: disc;
    }
  }
}
